<template>
  <v-app class="d-flex">
    <v-navigation-drawer class="w-auto h-auto">
      <div class="angry-grid">
        <NuxtLink id="item-0" to="/">
          <div class="text-h6">
            Menu
          </div>
        </NuxtLink>

        <main-menu id="item-1" />
        <signout id="item-2" />
      </div>
    </v-navigation-drawer>

    <v-main class="d-flex">
      <slot />
    </v-main>
  </v-app>
</template>

<script setup>

</script>

<style scoped>
.angry-grid {
  display: grid;

  grid-template-rows: 60px 60px 1fr 1fr;
  grid-template-columns: 1fr;

  gap: 0px;
  height: 100%;
}

#item-0 {
  grid-row-start: 1;
  grid-column-start: 1;

  grid-row-end: 2;
  grid-column-end: 2;
  justify-self: center;
  margin: 8px;
}
#item-1 {
  grid-row-start: 2;
  grid-column-start: 1;

  grid-row-end: 5;
  grid-column-end: 2;
}
#item-2 {
  grid-row-start: 5;
  grid-column-start: 1;

  grid-row-end: 6;
  grid-column-end: 2;
  margin: 4px;
}
</style>
