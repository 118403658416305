<template>
  <v-list v-model:opened="open">
    <v-list-item prepend-icon="mdi-home" title="Home" />

    <v-list-group value="Module">
      <template #activator="{ props }">
        <v-list-item
          v-bind="props"
          prepend-icon="mdi-office-building"
          title="HQ"
        />
      </template>

      <v-list-group value="Areas">
        <template #activator="{ props }">
          <v-list-item
            v-for="([title, icon], i) in HQ"
            :key="i"
            :prepend-icon="icon"
            :title="title"
            :value="title"
            v-bind="props"
          />
        </template>
      </v-list-group>
    </v-list-group>
  </v-list>
</template>

<script setup>
const items = [

  {
    title: 'Trader',
    icon: 'mdi-home',
    to: '/main/trader/home',
    variant: 'plain',
    color: 'primary',
    active: true
  },
  {
    title: 'Message',
    icon: 'mdi-home',
    to: '/main/message/items',
    variant: 'plain',
    color: 'primary',
    active: true
  },
  {
    title: 'Settings',
    icon: 'mdi-home',
    to: '/main/settings/items',
    variant: 'plain',
    color: 'primary',
    active: true
  }
]

const open = ref()
const HQ = ref([

  ['Finance', 'mdi-currency-usd'],
  ['HR', 'mdi-account-group'],
  ['Managing Director', 'mdi-account-tie'],
  ['Purchaser', 'mdi-cart'],
  ['Trader', 'mdi-chart-line']
])
</script>
